import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { clone, isEmpty, isNil } from "ramda";

import { getAlertTypeNames } from "../../utils/utils";

import palette from "../../../config/colorPalette";
import { Autocomplete, Box, Checkbox, Collapse, FormControlLabel, MenuItem, TextField } from "@mui/material";
import {
  handleActiveThresholds,
  handleInterMeasurementTime,
  handleZones,
  maxHandler,
  minHandler,
  onBlurHandler,
  onValueChangeHandler,
  updateData,
} from "./utils/utils";

import { ErrorText } from "./styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAssetsDataAction } from "../../redux/assetsSlice";
import { getConfigDataAction } from "../../redux/configSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const Configuration = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const assets = useSelector((state) => state.assetsData);
  const currentConfig = useSelector((state) => state.configData);
  const currentUser = useSelector((state) => state.userData.data);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedEquipmentID, setSelectedEquipmentID] = useState(null);
  const [assetSensors, setAssetSensors] = useState(null);
  const [channelsSensorsIDs, setchannelSensorsIDs] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [dataReloadTime, setDataReloadTime] = useState(0);
  const [alertTimeRange, setAlertTimeRange] = useState(0);
  const [measuresAlerts, setMeasuresAlerts] = useState([]);
  const [tuples, setTuples] = useState([]);
  const [tuplesActive, setTuplesActive] = useState([]);
  const [thresholdsRanges, setThresholdsRanges] = useState([]);
  const [equipments, setEquipments] = useState([]);

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const disabled = useMemo(() => !isEmpty(errors) || loading, [errors, loading]);
  const equipmentTypes = useSelector((state) => state.equipmentTypeData.data);
  const [objectMeasures, setObjectMeasures] = useState(null);
  const [currentEquipmentType, setCurrentEquipmentType] = useState(null);

  const [measureToggle, setMeasureToggle] = useState([]);
  const [channelToggle, setChannelToggle] = useState([]);
  const [zoneToggle, setZoneToggle] = useState([]);

  const [asignedChannelNames, setAsignedChannelNames] = useState();
  const [asignedChannelLength, setAsignedChannelLength] = useState();
  const [asignedChannelSpatialResolution, setAsignedChannelSpatialResolution] = useState();
  const [asignedChannelSamplingInterval, setAsignedChannelSamplingInterval] = useState();
  const [asignedChannelMeasurementTime, setAsignedChannelMeasurementTime] = useState();
  const [asignedChannelsStates, setAsignedChannelsStates] = useState();
  const [asignedChannelsInfo, setAsignedChannelsInfo] = useState();
  const [realState, setRealState] = useState(true);
  const [channelDataToggle, setChannelDataToggle] = useState();
  const [equipmentInterMeasurement, setEquipmentInterMeasurement] = useState();
  useEffect(() => {
    if (!isNil(equipmentTypes)) {
      const newData = equipmentTypes.map((item) => {
        const measures = [];
        const computing = [];
        item.pMeasureNames.forEach((measure, index) => {
          if (item.pMeasureComputed[index] === 0) {
            measures.push(measure);
          } else {
            computing.push(measure);
          }
        });

        const newEntry = { name: item.name, measures: measures };
        if (computing.length > 0) {
          newEntry.computing = computing;
        }
        return newEntry;
      });
      setObjectMeasures(newData);
    }
  }, [equipmentTypes]);

  useMemo(() => {
    if (!isNil(assets.data) && !isEmpty(assets.data) && !isNil(currentConfig.data)) {
      setSelectedAsset(assets.data[0]);
      setAlertTimeRange(assets.data[0].alertTimeRange / 60);
      setEquipments(assets.data[0].equipments ?? []);
      setSelectedEquipment(assets.data[0].equipments[0]);
      setSelectedEquipmentID(assets.data[0].equipments[0].equipmentID);
      setDataReloadTime(currentConfig.data.loadTime);
      const assetSensIDs = [];
      for (let index = 0; index < assets.data[0].sensors.length; index++) {
        assetSensIDs.push(assets.data[0].sensors[index].sensor_ID);
      }
      setAssetSensors(assetSensIDs);
    }
  }, [assets, currentConfig]);

  useEffect(() => {
    if (!isNil(selectedEquipment) && !isNil(objectMeasures) && realState) {
      setTuples(selectedEquipment.pThresholds);
      setTuplesActive(selectedEquipment.pThresholdsActive);
      setMeasuresAlerts(selectedEquipment.pMeasureAlerts);
      setThresholdsRanges(selectedEquipment.pThresholdsRanges);
      setAsignedChannelNames(selectedEquipment.channelsNames);
      setAsignedChannelLength(selectedEquipment.channelsLength);
      setAsignedChannelSpatialResolution(selectedEquipment.channelsSpatialResolution);
      setAsignedChannelSamplingInterval(selectedEquipment.channelsSamplingInterval);
      setAsignedChannelMeasurementTime(selectedEquipment.channelsMeasurementTime);
      setAsignedChannelsStates(selectedEquipment.channelsStates);
      setAsignedChannelsInfo(selectedEquipment.channelsInfo);
      setchannelSensorsIDs(selectedEquipment.channelsSensorsIDs);

      const newErrors = {};
      const suma = selectedEquipment.channelsMeasurementTime.reduce(
        (acumulador, valorActual) => acumulador + valorActual,
        0,
      );
      const falseStateChannels = selectedEquipment.channelsStates.reduce((indices, valor, indice) => {
        if (valor === true) {
          indices.push(indice);
        }
        return indices;
      }, []);
      let auxequipmentInterMeasurement = suma;
      for (let indexState = 0; indexState < falseStateChannels.length; indexState++) {
        auxequipmentInterMeasurement =
          auxequipmentInterMeasurement - selectedEquipment.channelsMeasurementTime[falseStateChannels[indexState]];
      }
      if (parseFloat(selectedEquipment.measureFrequency) < auxequipmentInterMeasurement) {
        newErrors[selectedEquipmentID + "_frequency"] = "not.higher.than.amount.measurement.time.channels";
      }
      setErrors(newErrors);
      setEquipmentInterMeasurement(suma);
      const type = objectMeasures.filter((equip) => equip.name === selectedEquipment.equipmentType);
      setCurrentEquipmentType(type);
      const newMeasureToggle = [];
      const newChannelToggle = [];
      const newZoneToggle = [];

      const newChannelDataToggle = [];
      for (let indexChannel = 0; indexChannel < selectedEquipment.channelsNames.length; indexChannel++) {
        newChannelDataToggle.push(false);
      }
      for (let indexMeasure = 0; indexMeasure < selectedEquipment.pThresholdsRanges.length; indexMeasure++) {
        if (type[0].measures.includes(selectedEquipment.pMeasureNames[indexMeasure])) {
          newMeasureToggle.push(false);
          newChannelToggle.push([]);
          newZoneToggle.push([]);
          for (
            let channelIndex = 0;
            channelIndex < selectedEquipment.pThresholdsRanges[indexMeasure].length;
            channelIndex++
          ) {
            newChannelToggle[indexMeasure].push(false);
            newZoneToggle[indexMeasure].push([]);
            for (
              let zoneIndex = 0;
              zoneIndex < selectedEquipment.pThresholdsRanges[indexMeasure][channelIndex].length;
              zoneIndex++
            ) {
              newZoneToggle[indexMeasure][channelIndex].push(false);
            }
          }
        }
      }
      setMeasureToggle(newMeasureToggle);
      setChannelToggle(newChannelToggle);
      setZoneToggle(newZoneToggle);
      setChannelDataToggle(newChannelDataToggle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEquipment]);

  useEffect(() => {
    if (!isNil(currentUser)) {
      dispatch(getAssetsDataAction(currentUser.id));
      dispatch(getConfigDataAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (assets.loading || isNil(currentUser)) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>
        {intl.formatMessage({ id: "asset.configuration" })}
      </div>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(assets) || isEmpty(assets) ? 400 : "80vh",
          width: "100%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
          overflow: "auto",
        }}
      >
        {!isNil(selectedAsset) && (
          <div
            style={{
              border: `1px solid ${palette.primary}`,
              position: "relative",
              margin: "2rem 0 0 1rem",
              padding: "0 1rem 1rem 1rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-2rem",
                backgroundColor: palette.likeWhite,
                padding: "0.2rem 0.4rem 0.2rem 0",
                left: "-1rem",
              }}
            >
              <TextField
                id="asset_to_configure"
                margin="dense"
                style={{ width: "10rem" }}
                variant="outlined"
                defaultValue={assets?.data?.[0]?.name}
                onChange={(e) => {
                  const selectedAssetName = e.target.value;
                  const selectedAsset = assets.data.find((asset) => asset.name === selectedAssetName);
                  setSelectedAsset(selectedAsset);
                  const assetSensIDs = [];
                  for (let index = 0; index < selectedAsset.sensors.length; index++) {
                    assetSensIDs.push(selectedAsset.sensors[index].sensor_ID);
                  }
                  setAssetSensors(assetSensIDs);
                  setEquipments(selectedAsset.equipments);
                  setRealState(true);
                  setSelectedEquipment(selectedAsset.equipments[0]);
                  setSelectedEquipmentID(selectedAsset.equipments[0].equipmentID);
                }}
                select
                label={intl.formatMessage({ id: "assets" })}
                required
              >
                {!isNil(assets.data) &&
                  assets.data.map((asset, index) => (
                    <MenuItem value={asset.name} key={asset.name + index}>
                      {asset.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div
              style={{
                border: `1px solid ${palette.primary}`,
                position: "relative",
                margin: "5rem 0 0 1rem",
                padding: "0 1rem 1rem 1rem",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-2rem",
                  backgroundColor: palette.likeWhite,
                  padding: "0.2rem 0.4rem 0.2rem 0",
                  left: "-1rem",
                }}
              >
                {!isNil(equipments) && !isEmpty(equipments) ? (
                  <TextField
                    id="equipment_to_configure"
                    margin="dense"
                    style={{ width: "10rem" }}
                    variant="outlined"
                    defaultValue={selectedEquipmentID}
                    value={selectedEquipmentID}
                    onChange={(e) => {
                      const selectedEquipmentIDOnChange = e.target.value;
                      const selectedEquipmentChange = equipments.find(
                        (equip) => equip.equipmentID === selectedEquipmentIDOnChange,
                      );
                      setRealState(true);
                      setSelectedEquipment(selectedEquipmentChange);
                      setSelectedEquipmentID(selectedEquipmentIDOnChange);
                      setAsignedChannelNames(selectedEquipmentChange.channelsNames);
                    }}
                    select
                    label={intl.formatMessage({ id: "equipment" })}
                    required
                  >
                    {equipments.map((equipment, index) => (
                      <MenuItem value={equipment.equipmentID} key={equipment.equipmentID + index}>
                        {equipment.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
              </div>

              {currentUser.role === "admin" && !isNil(setSelectedEquipmentID) && (
                <div style={{ fontSize: "x-large", marginTop: "3rem", width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", margin: "0.5rem" }}>
                    <TextField
                      id={selectedEquipmentID + "_frequency"}
                      style={{ margin: "0 0.2rem", width: "13%" }}
                      value={selectedEquipment.measureFrequency}
                      inputProps={{ min: 0, max: 86400 }}
                      onChange={(e) => {
                        setRealState(false);
                        handleInterMeasurementTime({
                          actualID: selectedEquipmentID + "_frequency",
                          asignedChannelMeasurementTime,
                          asignedChannelsStates,
                          equipmentInterMeasurement,
                          equipments,
                          errors,
                          setErrors,
                          selectedEquipmentID,
                          setSelectedEquipment,
                          setEquipments,
                          value: e.target.value,
                        });
                      }}
                      type="number"
                      error={errors[selectedEquipmentID + "_frequency"] ? true : false}
                      variant="outlined"
                      label={intl.formatMessage({ id: "measure.frequency" })}
                      required
                    ></TextField>
                    {!isNil(errors[selectedEquipmentID + "_frequency"]) && (
                      <ErrorText>{intl.formatMessage({ id: errors[selectedEquipmentID + "_frequency"] })}</ErrorText>
                    )}
                    {currentUser.role === "admin" && (
                      <>
                        <TextField
                          id={selectedEquipmentID + "_IP"}
                          style={{ margin: "0 0.2rem" }}
                          value={selectedEquipment.ipAddress}
                          onChange={(e) => {
                            const newErrors = { ...errors };
                            const regex =
                              /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
                            const isValidInput = regex.test(e.target.value);
                            if (isEmpty(e.target.value)) {
                              newErrors[e.target.id] = "not.empty";
                            } else if (!isValidInput) {
                              newErrors[e.target.id] = "must.ip";
                            } else {
                              delete newErrors[e.target.id];
                            }
                            setErrors(newErrors);
                            const index = equipments.findIndex(
                              (equipment) => equipment.equipmentID === selectedEquipmentID,
                            );
                            const newEquipments = [...equipments];
                            const updatedEquipment = { ...newEquipments[index] };
                            updatedEquipment.ipAddress = e.target.value;
                            newEquipments[index] = updatedEquipment;
                            setRealState(false);
                            setSelectedEquipment(updatedEquipment);
                            setEquipments(newEquipments);
                          }}
                          error={errors[selectedEquipmentID + "_IP"] ? true : false}
                          variant="outlined"
                          label={intl.formatMessage({ id: "ip.address" })}
                          required
                        ></TextField>
                        {!isNil(errors[selectedEquipmentID + "_IP"]) && (
                          <ErrorText>{intl.formatMessage({ id: errors[selectedEquipmentID + "_IP"] })}</ErrorText>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              {currentUser.role !== "admin" && (
                <div style={{ fontSize: "x-large", marginTop: "2rem", width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", margin: "0.5rem" }}></div>
                </div>
              )}
              {!isNil(asignedChannelNames) &&
                asignedChannelNames.length > 0 &&
                asignedChannelNames.map((channel, indexChannel) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap", // Mantiene todo en una sola fila
                        alignItems: "center", // Alinea todos los elementos al centro verticalmente
                        gap: "0.5rem",
                        marginLeft: "0.3rem",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center", // Alinea el icono verticalmente
                          whiteSpace: "nowrap", // Evita el salto de línea
                          margin: "0.1%",
                        }}
                        onClick={() => {
                          let newChannel = clone(channelDataToggle);
                          if (newChannel[indexChannel]) {
                            newChannel[indexChannel] = false;
                          } else {
                            newChannel[indexChannel] = true;
                            for (
                              let indexChannelData = 0;
                              indexChannelData < channelDataToggle.length;
                              indexChannelData++
                            ) {
                              if (indexChannelData !== indexChannel) {
                                newChannel[indexChannelData] = false;
                              }
                            }
                          }
                          setChannelDataToggle(newChannel);
                        }}
                      >
                        {intl.formatMessage({ id: "channel" })} {indexChannel + 1}
                        {!channelDataToggle[indexChannel] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                      </span>
                      <Collapse in={channelDataToggle[indexChannel]}>
                        <TextField
                          id={`channel_name_${indexChannel}`}
                          style={{ margin: "0 0.2rem 0.8rem" }}
                          value={asignedChannelNames[indexChannel]}
                          onChange={(e) => {
                            let newNames = clone(asignedChannelNames);
                            newNames[indexChannel] = e.target.value;
                            setAsignedChannelNames(newNames);
                          }}
                          error={errors[`channel_name_${indexChannel}`] ? true : false}
                          variant="outlined"
                          label={intl.formatMessage({ id: "channel.name" })}
                          required
                        ></TextField>
                        <TextField
                          id={`channel_length_${indexChannel}`}
                          style={{ margin: "0 0.2rem 0.8rem" }}
                          inputProps={{ min: 0 }}
                          value={asignedChannelLength[indexChannel]}
                          disabled={true}
                          error={errors[`channel_length_${indexChannel}`] ? true : false}
                          variant="outlined"
                          label={intl.formatMessage({ id: "channel.length" })}
                          type="number"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        ></TextField>
                        <TextField
                          id={`channel_spatial_resolution_${indexChannel}`}
                          style={{ margin: "0 0.2rem 0.8rem" }}
                          inputProps={{ min: 0 }}
                          value={asignedChannelSpatialResolution[indexChannel]}
                          onChange={(e) => {
                            let newResolutions = clone(asignedChannelSpatialResolution);
                            newResolutions[indexChannel] = parseFloat(e.target.value);
                            setAsignedChannelSpatialResolution(newResolutions);
                          }}
                          error={errors[`channel_spatial_resolution_${indexChannel}`] ? true : false}
                          variant="outlined"
                          label={intl.formatMessage({ id: "channel.spatial.resolution" })}
                          required
                          disabled={
                            selectedEquipment.manufacturer !== "Uptech Sensing" &&
                            selectedEquipment.manufacturer !== "AP Sensing"
                          }
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        ></TextField>
                        <TextField
                          id={`channel_sampling_interval_${indexChannel}`}
                          style={{ margin: "0 0.2rem 0.8rem" }}
                          inputProps={{ min: 0 }}
                          value={asignedChannelSamplingInterval[indexChannel]}
                          disabled={true}
                          error={errors[`channel_sampling_interval_${indexChannel}`] ? true : false}
                          variant="outlined"
                          label={intl.formatMessage({ id: "channel.sampling.interval" })}
                          required
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        ></TextField>
                        <TextField
                          id={`channel_measurement_time_${indexChannel}`}
                          style={{ margin: "0 0.2rem 0.8rem" }}
                          inputProps={{ min: 0 }}
                          value={asignedChannelMeasurementTime[indexChannel]}
                          onChange={(e) => {
                            let newMeasurementTimes = clone(asignedChannelMeasurementTime);
                            newMeasurementTimes[indexChannel] = parseFloat(e.target.value);
                            const newErrors = { ...errors };
                            const actualID = selectedEquipmentID + "_frequency";
                            let newSuma = selectedEquipment.measureFrequency;
                            for (
                              let indexNewMeasurement = 0;
                              indexNewMeasurement < newMeasurementTimes.length;
                              indexNewMeasurement++
                            ) {
                              if (asignedChannelsStates[indexNewMeasurement]) {
                                newSuma = newSuma - newMeasurementTimes[indexNewMeasurement];
                              }
                            }
                            if (
                              isEmpty(newMeasurementTimes[indexChannel]) ||
                              isNaN(newMeasurementTimes[indexChannel])
                            ) {
                              newErrors[e.target.id] = "not.empty";
                            } else if (parseFloat(newMeasurementTimes[indexChannel]) > newSuma) {
                              newErrors[actualID] = "not.higher.than.amount.measurement.time.channels";
                            } else if (parseFloat(newMeasurementTimes[indexChannel]) > 86400) {
                              newErrors[e.target.id] = "not.higher.than.day";
                            } else {
                              delete newErrors[actualID];
                              delete newErrors[e.target.id];
                            }
                            setErrors(newErrors);
                            setAsignedChannelMeasurementTime(newMeasurementTimes);
                          }}
                          error={errors[`channel_measurement_time_${indexChannel}`] ? true : false}
                          variant="outlined"
                          label={intl.formatMessage({ id: "channel.measurement.time" })}
                          required
                          disabled={
                            selectedEquipment.manufacturer !== "Uptech Sensing" &&
                            selectedEquipment.manufacturer !== "AP Sensing"
                          }
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        ></TextField>
                        {!isNil(errors[`channel_measurement_time_${indexChannel}`]) && (
                          <ErrorText>
                            {intl.formatMessage({ id: errors[`channel_measurement_time_${indexChannel}`] })}
                          </ErrorText>
                        )}
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={"checkbox" + indexChannel}
                              sx={{
                                color: palette.primary,
                                "&.Mui-checked": {
                                  color: palette.primary,
                                },
                              }}
                              checked={asignedChannelsStates[indexChannel]}
                              onChange={(e) => {
                                let newMA = clone(asignedChannelsStates);
                                newMA[indexChannel] = e.target.checked;
                                setRealState(false);
                                handleInterMeasurementTime({
                                  actualID: selectedEquipmentID + "_frequency",
                                  asignedChannelMeasurementTime,
                                  asignedChannelsStates,
                                  equipmentInterMeasurement,
                                  equipments,
                                  errors,
                                  selectedEquipmentID,
                                  setEquipments,
                                  setErrors,
                                  setSelectedEquipment,
                                  value: null,
                                });
                                setAsignedChannelsStates(newMA);
                              }}
                            />
                          }
                          label={intl.formatMessage({ id: "disable?" })}
                          style={{ marginLeft: "auto" }}
                        />
                        {asignedChannelsStates[indexChannel] ? (
                          <TextField
                            id={`channel_info_${indexChannel}`}
                            style={{ margin: "0 0.2rem 0.8rem" }}
                            inputProps={{ min: 0 }}
                            value={asignedChannelsInfo[indexChannel]}
                            onChange={(e) => {
                              let newChannelInfo = clone(asignedChannelsInfo);
                              newChannelInfo[indexChannel] = e.target.value;
                              setAsignedChannelsInfo(newChannelInfo);
                            }}
                            error={errors[`channel_info_${indexChannel}`] ? true : false}
                            variant="outlined"
                            label={intl.formatMessage({ id: "channel.info" })}
                          />
                        ) : null}
                        {!isEmpty(assetSensors) && (
                          <Autocomplete
                            multiple
                            id="multiple-checkboxes"
                            options={assetSensors}
                            sx={{ width: "85rem" }}
                            disableCloseOnSelect
                            onChange={(event, values) => {
                              let auxSensors = clone(channelsSensorsIDs);
                              auxSensors[indexChannel] = values;
                              setchannelSensorsIDs(auxSensors);
                            }}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                {intl.formatMessage({ id: option })}
                              </li>
                            )}
                            value={isNil(channelsSensorsIDs[indexChannel]) ? [] : channelsSensorsIDs[indexChannel]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={intl.formatMessage({ id: "sensor.ids" })}
                              />
                            )}
                          />
                        )}
                      </Collapse>
                    </div>
                  );
                })}

              <div style={{ fontSize: "x-large", marginTop: "1rem", width: "100%" }}>
                {intl.formatMessage({ id: "asset.configuration.thresholds" })}
              </div>
              {!isEmpty(measuresAlerts) &&
                selectedEquipment.pMeasureNames.map((measure, measureIndex) => {
                  if (!isNil(currentEquipmentType) && currentEquipmentType[0].computing.includes(measure)) {
                    return null;
                  }
                  return (
                    <>
                      <div style={{ fontSize: "x-large", display: "flex", alignItems: "center" }}>
                        <div
                          onClick={() => {
                            let newMeasureToggle = clone(measureToggle);
                            if (newMeasureToggle[measureIndex]) {
                              newMeasureToggle[measureIndex] = false;
                            } else {
                              newMeasureToggle[measureIndex] = true;
                            }
                            setMeasureToggle(newMeasureToggle);
                          }}
                        >
                          {intl.formatMessage({ id: measure })}{" "}
                          {!measureToggle[measureIndex] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={"checkbox" + measureIndex}
                                sx={{
                                  color: palette.primary,
                                  "&.Mui-checked": {
                                    color: palette.primary,
                                  },
                                }}
                                checked={measuresAlerts[measureIndex] === 1 ? true : false}
                                onChange={(e) => {
                                  let newMA = clone(measuresAlerts);
                                  if (e.target.checked) {
                                    newMA[measureIndex] = 1;
                                  } else {
                                    newMA[measureIndex] = 0;
                                  }
                                  setMeasuresAlerts(newMA);
                                }}
                              />
                            }
                            label={intl.formatMessage({ id: "send.alert" })}
                            style={{ marginLeft: "auto" }}
                          />
                        </div>
                      </div>

                      <Collapse in={measureToggle[measureIndex]}>
                        {thresholdsRanges.length > 0 &&
                          thresholdsRanges[measureIndex].map((channel, channelIndex) => {
                            return (
                              <>
                                <div
                                  style={{ fontSize: "large", width: "10%", marginLeft: "0.3rem" }}
                                  onClick={() => {
                                    let newChannelToggle = clone(channelToggle);
                                    if (newChannelToggle[measureIndex][channelIndex]) {
                                      newChannelToggle[measureIndex][channelIndex] = false;
                                    } else {
                                      newChannelToggle[measureIndex][channelIndex] = true;
                                    }
                                    setChannelToggle(newChannelToggle);
                                  }}
                                >
                                  {asignedChannelNames[channelIndex]}-{intl.formatMessage({ id: "channel.mayus" })}{" "}
                                  {channelIndex + 1}{" "}
                                  {!channelToggle[measureIndex][channelIndex] ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropUpIcon />
                                  )}
                                </div>

                                <Collapse in={channelToggle[measureIndex][channelIndex]}>
                                  <table style={{ borderCollapse: "collapse" }}>
                                    <tr>
                                      <th rowspan="2">
                                        <TextField
                                          id={`zone_addition${measureIndex}_${channelIndex}`}
                                          style={{ margin: "0.8rem 0.2rem 0.8rem" }}
                                          defaultValue={thresholdsRanges[measureIndex][channelIndex].length}
                                          inputProps={{ min: 1 }}
                                          onChange={(e) => {
                                            handleZones({
                                              e,
                                              tuples,
                                              channelIndex,
                                              errors,
                                              measureIndex,
                                              setErrors,
                                              setThresholdsRanges,
                                              setTuples,
                                              thresholdsRanges,
                                              tuplesActive,
                                              setTuplesActive,
                                              zoneToggle,
                                              setZoneToggle,
                                            });
                                          }}
                                          type="number"
                                          error={errors[`zone_addition${measureIndex}_${channelIndex}`] ? true : false}
                                          variant="outlined"
                                          label={intl.formatMessage({ id: "measure.zones" })}
                                          required
                                          sx={{ width: "40%" }}
                                        ></TextField>
                                      </th>
                                      <th rowspan="2">
                                        <h2>{intl.formatMessage({ id: "range.min" })}</h2>
                                      </th>
                                      <th rowspan="2">
                                        <h2>{intl.formatMessage({ id: "range.max" })}</h2>
                                      </th>
                                      <th colspan="3">
                                        <h3>{intl.formatMessage({ id: "thresholds" })}</h3>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>
                                        <h3>{intl.formatMessage({ id: "low" })}</h3>
                                      </th>
                                      <th>
                                        <h3>{intl.formatMessage({ id: "intermediate" })}</h3>
                                      </th>
                                      <th>
                                        <h3>{intl.formatMessage({ id: "high" })}</h3>
                                      </th>
                                    </tr>
                                    {thresholdsRanges[measureIndex][channelIndex].map((zone, zoneIndex) => {
                                      const parts = thresholdsRanges[measureIndex][channelIndex][zoneIndex].split("-");

                                      const num1 = parseInt(parts[0], 10);
                                      const num2 = parseInt(parts[1], 10);

                                      const bounds = getAlertTypeNames(intl);

                                      if (tuples[measureIndex][channelIndex][zoneIndex].length < 3) {
                                        bounds.splice(
                                          0,
                                          tuples[measureIndex][channelIndex][zoneIndex].length === 2 ? 1 : 2,
                                        );
                                      }
                                      return (
                                        <tr className="channel_row">
                                          <td>
                                            <center>
                                              <h2>
                                                {intl.formatMessage({ id: "zone" })} {zoneIndex + 1}
                                              </h2>
                                            </center>
                                          </td>
                                          <td className="input">
                                            <center>
                                              <div className="input-wrapper">
                                                <div className="input-row">
                                                  <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    sx={{ width: "50%" }}
                                                    key={`equipment_${measureIndex}_${channelIndex}_${zoneIndex}_0`}
                                                    error={
                                                      errors[`equipment_${measureIndex}_${channelIndex}_${zoneIndex}_0`]
                                                        ? true
                                                        : false
                                                    }
                                                    value={num1}
                                                    onChange={(e) => {
                                                      minHandler({
                                                        e,
                                                        num1,
                                                        num2,
                                                        thresholdsRanges,
                                                        setThresholdsRanges,
                                                        measureIndex,
                                                        channelIndex,
                                                        zoneIndex,
                                                        errors,
                                                        setErrors,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                                {!isNil(
                                                  errors[`equipment_${measureIndex}_${channelIndex}_${zoneIndex}_0`],
                                                ) && (
                                                  <ErrorText className="error-message">
                                                    {intl.formatMessage({
                                                      id: errors[
                                                        `equipment_${measureIndex}_${channelIndex}_${zoneIndex}_0`
                                                      ],
                                                    })}
                                                  </ErrorText>
                                                )}
                                              </div>
                                            </center>
                                          </td>
                                          <td item xs={1} className="input">
                                            <center>
                                              <div className="input-wrapper">
                                                <div className="input-row">
                                                  <TextField
                                                    sx={{ width: "50%" }}
                                                    key={`equipment_${measureIndex}_${channelIndex}_${zoneIndex}_1`}
                                                    type="number"
                                                    variant="outlined"
                                                    value={num2}
                                                    error={
                                                      errors[`equipment_${measureIndex}_${channelIndex}_${zoneIndex}_1`]
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      maxHandler({
                                                        e,
                                                        num1,
                                                        num2,
                                                        thresholdsRanges,
                                                        setThresholdsRanges,
                                                        measureIndex,
                                                        channelIndex,
                                                        zoneIndex,
                                                        errors,
                                                        setErrors,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                                {!isNil(
                                                  errors[`equipment_${measureIndex}_${channelIndex}_${zoneIndex}_1`],
                                                ) && (
                                                  <ErrorText className="error-message">
                                                    {intl.formatMessage({
                                                      id: errors[
                                                        `equipment_${measureIndex}_${channelIndex}_${zoneIndex}_1`
                                                      ],
                                                    })}
                                                  </ErrorText>
                                                )}
                                              </div>
                                            </center>
                                          </td>
                                          {tuples[measureIndex][channelIndex][zoneIndex].map((threshValue, index2) => (
                                            <>
                                              <td className="input">
                                                <center>
                                                  <div className="input-wrapper">
                                                    <div className="input-row">
                                                      <TextField
                                                        id={measure + measureIndex + threshValue}
                                                        sx={{ width: "50%" }}
                                                        inputProps={{ step: "0.1" }}
                                                        value={threshValue}
                                                        disabled={
                                                          tuplesActive[measureIndex][channelIndex][zoneIndex][
                                                            index2
                                                          ] !== 1
                                                        }
                                                        onChange={(e) =>
                                                          onValueChangeHandler({
                                                            value: !isEmpty(e.target.value)
                                                              ? parseFloat(e.target.value)
                                                              : null,
                                                            measureIndex,
                                                            channelIndex,
                                                            zoneIndex,
                                                            index2,
                                                            tuples,
                                                            setTuples,
                                                            tuplesActive,
                                                            setTuplesActive,
                                                          })
                                                        }
                                                        error={
                                                          errors[
                                                            `threshold-${measureIndex}-${channelIndex}-${zoneIndex}-value-${index2}`
                                                          ]
                                                            ? true
                                                            : false
                                                        }
                                                        onBlur={() =>
                                                          onBlurHandler({
                                                            idErrorPrefix: `threshold-${measureIndex}-${channelIndex}-${zoneIndex}-value`,
                                                            measureIndex,
                                                            channelIndex,
                                                            zoneIndex,
                                                            tuples,
                                                            errors,
                                                            setErrors,
                                                            setTuples,
                                                            tuplesActive,
                                                            setTuplesActive,
                                                            intl,
                                                          })
                                                        }
                                                        type="number"
                                                        variant="outlined"
                                                        required
                                                      />
                                                      <Checkbox
                                                        id={measure + measureIndex + threshValue + "_checkbox"}
                                                        sx={{
                                                          color: palette.primary,
                                                          "&.Mui-checked": {
                                                            color: palette.primary,
                                                          },
                                                        }}
                                                        checked={
                                                          tuplesActive[measureIndex][channelIndex][zoneIndex][
                                                            index2
                                                          ] === 1
                                                        }
                                                        onChange={(event) => {
                                                          handleActiveThresholds({
                                                            event,
                                                            channelIndex,
                                                            index2,
                                                            measureIndex,
                                                            setTuples,
                                                            setTuplesActive,
                                                            tuples,
                                                            tuplesActive,
                                                            zoneIndex,
                                                          });
                                                        }}
                                                      />
                                                    </div>
                                                    {errors[
                                                      `threshold-${measureIndex}-${channelIndex}-${zoneIndex}-value-${index2}`
                                                    ] && (
                                                      <ErrorText className="error-message">
                                                        {intl.formatMessage({
                                                          id: errors[
                                                            `threshold-${measureIndex}-${channelIndex}-${zoneIndex}-value-${index2}`
                                                          ],
                                                        })}
                                                      </ErrorText>
                                                    )}
                                                  </div>
                                                </center>
                                              </td>
                                            </>
                                          ))}
                                        </tr>
                                      );
                                    })}
                                  </table>
                                </Collapse>
                              </>
                            );
                          })}
                      </Collapse>
                    </>
                  );
                })}
              {!isEmpty(measuresAlerts) &&
                selectedEquipment.pMeasureNames.map((measure, measureIndex) => {
                  if (!isNil(currentEquipmentType) && !currentEquipmentType[0].computing.includes(measure)) {
                    return null;
                  }
                  return (
                    <>
                      <div style={{ fontSize: "x-large", display: "flex", alignItems: "center" }}>
                        {intl.formatMessage({ id: measure })}
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={"checkbox" + measureIndex}
                                sx={{
                                  color: palette.primary,
                                  "&.Mui-checked": {
                                    color: palette.primary,
                                  },
                                }}
                                checked={measuresAlerts[measureIndex] === 1 ? true : false}
                                onChange={(e) => {
                                  let newMA = clone(measuresAlerts);
                                  if (e.target.checked) {
                                    newMA[measureIndex] = 1;
                                  } else {
                                    newMA[measureIndex] = 0;
                                  }
                                  setMeasuresAlerts(newMA);
                                }}
                              />
                            }
                            label={intl.formatMessage({ id: "send.alert" })}
                            style={{ marginLeft: "auto" }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <LoadingButton
              variant="contained"
              size="large"
              style={{
                marginTop: "1rem",
                padding: "0.2rem 0.5rem",
                backgroundColor: disabled ? palette.light : palette.primary,
                color: "white",
              }}
              loading={loading ?? false}
              disabled={disabled}
              onClick={() => {
                updateData({
                  selectedAsset,
                  selectedEquipment,
                  asignedChannelNames,
                  asignedChannelLength,
                  asignedChannelMeasurementTime,
                  asignedChannelSamplingInterval,
                  asignedChannelSpatialResolution,
                  asignedChannelsStates,
                  asignedChannelsInfo,
                  channelsSensorsIDs,
                  alertTimeRange,
                  tuples,
                  tuplesActive,
                  measuresAlerts,
                  thresholdsRanges,
                  equipments,
                  setLoading,
                  dispatch,
                  assets,
                  role: currentUser.role,
                  currentUser,
                  intl,
                });
              }}
            >
              {intl.formatMessage({ id: "save" })}
            </LoadingButton>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Configuration;
