import { clone, isEmpty, isNil } from "ramda";

export const handleChannels = ({
  e,
  thresholdsValues,
  setThresholdsValues,
  thresholdsRanges,
  setThresholdsRanges,
  zones,
  setZones,
  zoneToggle,
  errors,
  setErrors,
  indexEquipment,
  indexMeasure,
  channelIndex,
}) => {
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[e.target.id] = "not.empty";
  } else {
    delete newErrors[e.target.id];
  }
  setErrors(newErrors);
  let newZones = clone(zones);
  newZones[indexEquipment][indexMeasure][channelIndex] = parseInt(e.target.value);
  let newThresholdValues = clone(thresholdsValues);
  let newThresholdRanges = clone(thresholdsRanges);
  let newZoneToggle = clone(zoneToggle);
  for (let newValue = 0; newValue < parseInt(e.target.value); newValue++) {
    if (isNil(newThresholdValues[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][newValue])) {
      const test = newThresholdValues[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex];
      test.push([]);
      newThresholdValues[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][newValue] = [
        { type: null, value: 0 },
      ];
    }
    if (isNil(newThresholdRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][newValue])) {
      newThresholdRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex].push([]);
      newThresholdRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][newValue] = [
        newThresholdRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][newValue - 1][1] + 1,
        newThresholdRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][newValue - 1][1] + 2,
      ];
    }
    if (isNil(newZoneToggle[indexEquipment][indexMeasure][channelIndex][newValue])) {
      newZoneToggle[indexEquipment][indexMeasure][channelIndex].push(false);
    }
  }
  setThresholdsRanges(newThresholdRanges);
  setThresholdsValues(newThresholdValues);
  setZones(newZones);
};
export const minHandler = ({
  e,
  thresholdsRanges,
  setThresholdsRanges,
  indexEquipment,
  indexMeasure,
  channelIndex,
  zoneIndex,
  errors,
  setErrors,
}) => {
  const prevId = `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex - 1}_1`;
  const id = `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`;
  const nextId = `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`;
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[id] = "not.empty";
  }
  let newRange = thresholdsRanges;
  const value = parseFloat(e.target.value);
  const previousZone = newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex - 1];
  if (
    isNil(previousZone) &&
    value < newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][1]
  ) {
    delete newErrors[id];
    delete newErrors[nextId];
  } else if (
    isNil(previousZone) &&
    value >= newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][1]
  ) {
    newErrors[id] = "min.lower.max";
  }
  if (!isNil(previousZone)) {
    if (
      previousZone[1] < value &&
      value < newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][1]
    ) {
      delete newErrors[prevId];
      delete newErrors[id];
      delete newErrors[nextId];
    } else if (
      previousZone[1] >= value ||
      value <= newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][1]
    ) {
      newErrors[id] = "prevMax.thisMax";
    }
  }
  newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][0] = value;
  setThresholdsRanges(newRange);
  setErrors(newErrors);
};

export const maxHandler = ({
  e,
  thresholdsRanges,
  setThresholdsRanges,
  indexEquipment,
  indexMeasure,
  channelIndex,
  zoneIndex,
  polylineDisplay,
  equipment,
  errors,
  setErrors,
}) => {
  const prevId = `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`;
  const id = `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`;
  const nextId = `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex + 1}_0`;
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[id] = "not.empty";
  }
  let newRange = thresholdsRanges;
  const value = parseFloat(e.target.value);
  const nextZone = newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex + 1];
  if (isNil(nextZone) && value > newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][0]) {
    delete newErrors[prevId];
    delete newErrors[id];
  } else if (
    isNil(nextZone) &&
    value <= newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][0]
  ) {
    newErrors[id] = "max.higher.min";
  }
  if (!isNil(nextZone)) {
    if (
      nextZone[0] > value &&
      value > newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][0]
    ) {
      delete newErrors[prevId];
      delete newErrors[id];
      delete newErrors[nextId];
    } else if (
      nextZone[0] <= value ||
      value <= newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][0]
    ) {
      newErrors[id] = "thisMin.nextMin";
    }
  }
  const indexPoly = equipment.channels[channelIndex];
  if (value > polylineDisplay[indexPoly].points[polylineDisplay[indexPoly].points.length - 1].pk) {
    newErrors[id] = "higher.than.highest.pk";
  }
  newRange[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][zoneIndex][1] = value;
  setThresholdsRanges(newRange);
  setErrors(newErrors);
};

export const handleChannelDisability = ({
  actualID,
  equipment,
  indexEquipment,
  indChan,
  equipmentInterMeasurement,
  setEquipment,
  setEquipmentInterMeasurement,
  newState,
  errors,
  setErrors,
}) => {
  const newEquipments = [...equipment];
  const updatedEquipment = { ...newEquipments[indexEquipment] };
  const actualMeasureFrequency = updatedEquipment.channelsMeasurementTime[indChan];
  let newEquipmentInterMeasurement = clone(equipmentInterMeasurement);
  if (!newState) {
    newEquipmentInterMeasurement[indexEquipment] =
      newEquipmentInterMeasurement[indexEquipment] + actualMeasureFrequency;
  } else {
    newEquipmentInterMeasurement[indexEquipment] =
      newEquipmentInterMeasurement[indexEquipment] - actualMeasureFrequency;
  }
  updatedEquipment.channelsStates[indChan] = newState;
  const newErrors = { ...errors };
  if (isEmpty(updatedEquipment.measureFrequency) || isNaN(updatedEquipment.measureFrequency)) {
    newErrors[actualID] = "not.empty";
  } else if (parseFloat(updatedEquipment.measureFrequency) < newEquipmentInterMeasurement[indexEquipment]) {
    newErrors[actualID] = "not.higher.than.amount.measurement.time.channels";
  } else if (parseFloat(updatedEquipment.measureFrequency) > 86400) {
    newErrors[actualID] = "not.higher.than.day";
  } else {
    delete newErrors[actualID];
  }
  setErrors(newErrors);
  newEquipments[indexEquipment] = updatedEquipment;
  setEquipment(newEquipments);
  setEquipmentInterMeasurement(newEquipmentInterMeasurement);
};
