/* eslint-disable array-callback-return */
import { clone, isEmpty, isNil } from "ramda";
import { Marker, Popup } from "react-leaflet";
import Button from "@mui/material/Button";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import { ALERT_COLORS } from "../../../../../utils/consts";
import { getAlertThemeData } from "../../../../../utils/utils";
import palette from "../../../../../../config/colorPalette";

export const getCentroid = (points) => {
  if (isNil(points) || isEmpty(points)) {
    return [0, 0];
  }
  let latSum = 0,
    longSum = 0;
  points.forEach((point) => {
    latSum += point[0];
    longSum += point[1];
  });
  return [latSum / points.length, longSum / points.length];
};

export const getMeasure = (measures, mins, maxs, thresholds, domain) => {
  let result = 0;
  for (let index = 0; index < measures.length; index++) {
    const number = measures[index];
    const th = clone(thresholds);
    while (th.length < domain.length) {
      if (!isNil(th[th.length - 1])) {
        th.push(th[th.length - 1]);
      } else {
        th.push(Infinity);
      }
    }
    for (let j = domain.length - 1; j >= 0; j--) {
      if (th[j] <= number) {
        if (result < domain[j]) {
          result = domain[j];
        }
        if (j === domain.length - 1) {
          return result;
        }
      }
    }
    if (number < th[0]) {
      let temp = (number - mins[index]) / (maxs[index] - mins[index]);
      temp = temp < 0 ? Math.abs(temp) : temp;
      if (temp > 1 || temp < -1) {
        temp = 1;
      }
      if (result < temp) {
        result = temp;
      }
    }
  }
  return result;
};
export const getMeasureState = (measures, thresholds) => {
  let result = 0;
  for (let index = 0; index < measures.length; index++) {
    const number = measures[index];
    const th = clone(thresholds[index]);
    for (let thIndex = 0; thIndex < th.length; thIndex++) {
      if (number >= th[thIndex]) {
        result = 1;
      }
    }
  }
  return result;
};

export const getLineColor = ({ filter, asset, equipment, data, max, min, p, tColorScale, umbrales }) => {
  const th = umbrales;
  const values = !isNil(p.values) ? p.values : p.measuresValue;
  const measure =
    filter === "status" || filter === "status.secondary"
      ? getMeasureState(values, th)
      : getMeasure(values, [min], [max], th, tColorScale.domain());
  //const measure =
  //  (filter === "temperature" || filter === "strain"
  //    ? getMeasure(p.values, min, max, th, tColorScale.domain())
  //    : getMeasure(p.values, mins, maxs, th, [0.5, 0.7, 0.9])) ?? 0;
  return tColorScale(measure);
};

export const getMaxMin = (polylines) => {
  const measureTypesCount = polylines?.[0]?.p1?.values?.length;
  const maxValues = [];
  const minValues = [];

  for (let measureIndex = 0; measureIndex < measureTypesCount; measureIndex++) {
    let currentMax = 0;
    let currentMin = 0;
    for (let index = 0; index < polylines?.length; index++) {
      const value = polylines?.[index]?.p1?.values?.[measureIndex];
      currentMax = index === 0 ? value : currentMax < value ? value : currentMax;
      currentMin = index === 0 ? value : currentMin > value ? value : currentMin;
    }
    maxValues.push(currentMax);
    minValues.push(currentMin);
  }
  return { maxValues, minValues };
};

export const shouldAddAccordingToState = ({
  indexes,
  index,
  thresholds,
  values,
  prevValues,
  filteredPoints,
  measures,
}) => {
  let measureValue = []; //values tendria que venir [temperatura, strain, leak, landslide] (dependiendo del orden en el que llegue)
  for (let i = 0; i < measures.length; i++) {
    if (!isNil(values?.[measures[i]]?.[index])) {
      measureValue.push(values[measures[i]][index]);
    }
  }
  let alertType = getAlertType(values, thresholds); // 0 -> OK, 1 -> low, 2 -> intermediate, 3 -> high
  if (indexes.includes(index) || prevValues.state !== alertType) {
    prevValues.values = values;
    prevValues.state = alertType;
    return { should: true, values };
  } else {
    filteredPoints[filteredPoints.length - 1].measuresValue = clone(prevValues.values);
    filteredPoints[filteredPoints.length - 1].alertType = alertType;
    prevValues = { state: alertType, values: values.map((v, i) => Math.max(v, prevValues.values[i])) };
  }
  return { should: false, measureValue: [] };
};

export const shouldAddAccordingToMeasure = ({
  indexes,
  index,
  thresholds,
  difference,
  value,
  prevValues,
  filteredPoints,
}) => {
  if (!isNil(value)) {
    let alertType = getAlertType([value], [thresholds]); // 0 -> OK, 1 -> low, 2 -> intermediate, 3 -> high

    if (
      indexes.includes(index) ||
      prevValues?.state !== alertType ||
      Math.abs(value - prevValues?.values) > difference
    ) {
      prevValues.values = [value];
      prevValues.state = alertType;
      return { should: true, measureValue: [value] };
    } else {
      filteredPoints[filteredPoints.length - 1].measuresValue = Math.max(value, prevValues?.values?.[0]);
      filteredPoints[filteredPoints.length - 1].alertType = alertType;
    }
  }
  return { should: false, measureValue: [] };
};

export const getAlertType = (values, thresholds) => {
  let alertType = 0; // 0 -> OK, 1 -> low, 2 -> intermediate, 3 -> high
  for (let i = 0; i < values.length && alertType < 3; i++) {
    const item = values[i];
    if (!isNil(thresholds[i])) {
      for (let j = thresholds[i].length - 1, type = 3; j >= 0 && alertType < 3; j--, type--) {
        const th = thresholds[i][j];
        if (th <= item && alertType < type) {
          alertType = type;
        }
      }
    }
  }
  return alertType;
};

export const getPolylineAlertsMapMark = ({
  filter,
  alerts,
  points,
  filtered_asset,
  assets,
  polylines,
  currentAssetMeasure,
  customIcon,
  selectedEquipment,
  currentZoom,
  objectMeasures,
  stateMagnitudes,
  intl,
  navigate,
}) => {
  if (isNil(assets) || isNil(polylines)) {
    return null;
  }
  const assetsInPolylines = [];
  for (let index = 0; index < polylines.length; index++) {
    assetsInPolylines.push(polylines[index][polylines[index].length - 1].asset);
  }
  const test = {
    status: stateMagnitudes,
  };
  const markers = [];
  const lengthAssets = assets.length;
  for (let assetIndex = 0; assetIndex < lengthAssets; assetIndex++) {
    const asset = assets[assetIndex];
    const assetAlerts = alerts?.filter((a) => a.asset === asset.name);
    if (filter === "status" || filter === "status.secondary" || asset.name === filtered_asset) {
      for (let indexPolyline = 0; indexPolyline < polylines.length; indexPolyline++) {
        for (let index = 0; index < assetAlerts.length; index++) {
          if (!assetAlerts[index].isArchived) {
            if (filter === "status" || filter === "status.secondary" || filter === assetAlerts[index].measure) {
              const indexEquipment = asset.equipments.findIndex((item) => item.equipmentID === selectedEquipment);
              if (indexEquipment >= 0) {
                const equipment = asset.equipments[indexEquipment];
                const equipmentType = objectMeasures.find((item) => item.name === equipment.equipmentType);
                const indexMeasure = equipment.pMeasureNames.indexOf(assetAlerts[index].measure);
                const umbrales = [];
                if (filter === "status" || filter === "status.secondary") {
                  const notComputed = equipmentType.measures;
                  const computed = equipmentType.computing;

                  for (let index = 0; index < notComputed.length; index++) {
                    if (stateMagnitudes.includes(notComputed[index])) {
                      const indexOF = equipment.pMeasureNames.indexOf(notComputed[index]);
                      umbrales.push([]);
                      const thisThreshold = equipment.pThresholds[indexOF];
                      const thisActive = equipment.pThresholdsActive[indexOF];
                      for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
                        if (thisActive[indexActive] === 1) {
                          umbrales[umbrales.length - 1].push(thisThreshold[indexActive]);
                        }
                      }
                    }
                  }

                  for (let index = 0; index < computed.length; index++) {
                    if (stateMagnitudes.includes(computed[index])) {
                      umbrales.push([]);
                      umbrales[umbrales.length - 1].push(0.5);
                    }
                  }
                } else {
                  if (!equipmentType.computing.includes(filter)) {
                    const thisThreshold = equipment.pThresholds[indexMeasure];
                    const thisActive = equipment.pThresholdsActive[indexMeasure];
                    for (let indexActive = 0; indexActive < thisActive.length; indexActive++) {
                      if (thisActive[indexActive] === 1) {
                        umbrales.push(thisThreshold[indexActive]);
                      }
                    }
                  } else {
                    umbrales.push(0.5);
                  }
                }
                if (
                  equipment.pMeasureAlerts[indexMeasure] === 1 &&
                  equipment.pMeasureNames[indexMeasure] !== "depth_of_burial" &&
                  (filter === assetAlerts[index].measure || test[filter].includes(assetAlerts[index].measure))
                ) {
                  let count = 0;
                  let correctIndex = 0;
                  for (let j = 0; j < assetsInPolylines.length; j++) {
                    if (assetsInPolylines[j] === asset.name) {
                      count++;
                      if (count === assetAlerts[index].channel) {
                        correctIndex = j;
                        break;
                      }
                    }
                  }
                  printMarkers({
                    asset,
                    equipment,
                    channel: assetAlerts[index].channel,
                    points: points.data[`${asset.name}${assetAlerts[index].channel}`],
                    alert: assetAlerts[index],
                    markers,
                    customIcon,
                    polyline: polylines[correctIndex],
                    plineIndex: correctIndex,
                    umbrales,
                    currentZoom,
                    currentAssetMeasure,
                    stateMagnitudes,
                    intl,
                    navigate,
                  });
                }
              }
            }
          }
        }
      }
    }
  }
  return markers;
};

const getMP = (p1, p2, offset_v1_latitude, offset_v1_longitude) => {
  var lat = 0;
  var lon = 0;
  var lat2 = 0;
  var lon2 = 0;
  var sumX = 0;
  var sumY = 0;
  var sumZ = 0;

  lat = (p1.latitude + offset_v1_latitude) * (Math.PI / 180);
  lat2 = (p2.latitude + offset_v1_latitude) * (Math.PI / 180);
  lon = (p1.longitude + offset_v1_longitude) * (Math.PI / 180);
  lon2 = (p2.longitude + offset_v1_longitude) * (Math.PI / 180);
  sumX = Math.cos(lat) * Math.cos(lon) + Math.cos(lat2) * Math.cos(lon2);
  sumY = Math.cos(lat) * Math.sin(lon) + Math.cos(lat2) * Math.sin(lon2);
  sumZ = Math.sin(lat) + Math.sin(lat2);

  var avgX = sumX / 2;
  var avgY = sumY / 2;
  var avgZ = sumZ / 2;

  lon = Math.atan2(avgY, avgX) * (180 / Math.PI);
  var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  lat = Math.atan2(avgZ, hyp) * (180 / Math.PI);

  return [lat, lon];
};
export function asociarNumeroConLista(numero, lista) {
  var rango = [11, 12, 13, 14, 15, 16];

  if (rango.includes(numero)) {
    var indice = numero - rango[0];
    return lista[indice];
  } else {
    return "Número fuera del rango especificado";
  }
}
const printMarkers = ({
  asset,
  equipment,
  channel,
  points,
  alert,
  markers,
  customIcon,
  polyline,
  currentZoom,
  currentAssetMeasure,
  umbrales,
  plineIndex,
  stateMagnitudes,
  intl,
  navigate,
}) => {
  let offset_v1_latitude = 0;
  let offset_v1_longitude = 0;
  const lista = [0.003525 / 4, 0.003 / 4, 0.002475 / 4, 0.00195 / 4, 0.00145 / 4, 0.0009 / 4];
  const asociado = asociarNumeroConLista(currentZoom, lista);
  const repeated = polyline[polyline.length - 1].equal;
  const realIndex = plineIndex - Math.min(...repeated);
  const selChan = polyline[polyline.length - 1].selectedChannels;
  const length = repeated.length;
  if (repeated.includes(plineIndex) && length > 1) {
    if (isEmpty(selChan) || selChan.includes(plineIndex)) {
      const half = Math.round(length / 2);
      const m = realIndex + 1 > half ? 1 : -1;
      const extra = length % 2 === 0 ? Math.round((realIndex + 1) / half) : Math.round(realIndex / half);
      const distance = asociado * m * extra;
      const pointIni = [polyline[0].p1.latitude, polyline[0].p1.longitude];
      const pointEnd = [polyline[polyline.length - 1].p2.latitude, polyline[polyline.length - 1].p2.longitude];
      const atanV1 = Math.atan2(pointEnd[0] - pointIni[0], pointEnd[1] - pointIni[1]);
      offset_v1_latitude = -distance * Math.cos(atanV1);
      offset_v1_longitude = distance * Math.sin(atanV1);
    }
  }

  const middlepoint = getMP(
    points.points[alert.startIndex],
    points.points[alert.endIndex],
    offset_v1_latitude,
    offset_v1_longitude,
  );

  const alertData = getAlertThemeData(alert.type, "large", intl);
  const value = alert.value;
  markers.push(
    <Marker key={asset.name + "_middlepoint_" + middlepoint + markers.length} position={middlepoint} icon={customIcon}>
      <Popup>
        <div>
          <div
            style={{
              marginBottom: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {alertData.icon}
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "0.1rem", gap: "0.5rem" }}>
            <span style={{ fontWeight: "600" }}>{intl.formatMessage({ id: "date" })}:</span>
            <span>{alert.dateStart}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem", gap: "0.5rem" }}>
            <span style={{ fontWeight: "600" }}>PK:</span>
            <span>{points.points[alert.startIndex].pk}</span>
          </div>
          {!isEmpty(equipment.pMeasureNames) && (
            <table style={{ borderSpacing: "0", border: `1px solid ${palette.primary}` }}>
              <thead>
                <tr style={{ textAlign: "center", backgroundColor: palette.primary, color: palette.likeWhite }}>
                  <td
                    style={{
                      borderRight: `2px solid ${palette.likeWhite}`,
                    }}
                  >
                    {intl.formatMessage({ id: "measure" })}
                  </td>
                  <td>{intl.formatMessage({ id: "value" })}</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    backgroundColor: ALERT_COLORS[alert.type],
                    fontWeight: alert.type > 0 ? "600" : "400",
                  }}
                >
                  <td
                    style={{
                      padding: "0.1rem 0.5rem",
                      borderRight: `2px solid ${palette.likeWhite}`,
                    }}
                  >
                    {intl.formatMessage({ id: alert.measure })}
                  </td>
                  <td
                    style={{
                      padding: "0.1rem 0.5rem",
                    }}
                  >
                    {value}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div style={{ marginTop: "0.5rem", display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <Button
              sx={{
                fontSize: "0.7rem",
                color: palette.primary,
                borderColor: palette.primary,
              }}
              size="small"
              variant="outlined"
              startIcon={<QueryStatsIcon />}
              onClick={() => {
                const date = alert.dateStart.replace(" ", "T");
                navigate(`/analysis/${asset.name}/${encodeURIComponent(date)}/${alert.startIndex}/${alert.endIndex}`);
              }}
            >
              {intl.formatMessage({ id: "analysis" })}
            </Button>
          </div>
        </div>
      </Popup>
    </Marker>,
  );
};

export const alarmsQuantity = (alarms) => {
  let alerts = [];
  for (let i = 1; i < ALERT_COLORS.length; i++) {
    alerts.push(0);
  }
  if (!isNil(alarms) && !isEmpty(alarms)) {
    for (let i = 0; i < alarms.length; i++) {
      alerts[alarms[i].type - 1]++;
    }
  }
  return alerts;
};

export const printEquipments = (keyPrefix, equipments, customEquipmentIcon, customEquipmentAlertIcon, intl) => {
  const markerEquipments = [];
  equipments.map((equip, equipIndex) => {
    const markerIcon = equip.statusDetails === "OK" && equip.alive ? customEquipmentIcon : customEquipmentAlertIcon;
    const position = [equip.latitude, equip.longitude];
    markerEquipments.push(
      <Marker key={`${keyPrefix}-${equipIndex}`} position={position} icon={markerIcon}>
        <Popup>
          {intl.formatMessage({ id: "status.equipment" })}: {intl.formatMessage({ id: equip.statusDetails })}
          <br />
          Alive:
          <span
            style={{
              backgroundColor: equip.alive ? "green" : "red",
              width: "10px",
              height: "10px",
              display: "inline-block",
              marginLeft: "5px",
            }}
          ></span>
          <br />
          {intl.formatMessage({ id: "last.connection" })}: {equip.lastConnection.replace("T", " ")}
        </Popup>
      </Marker>,
    );
  });
  return markerEquipments;
};

export const getBoundaries = (bIndex, assets) => {
  if (!isNil(assets)) {
    const result = [];
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      result.push([]);
      for (let j = 0; j < asset.pBoundaries.length; j++) {
        const bound = asset.pBoundaries[j];
        result[i].push(bound[bIndex]);
      }
    }
    return result;
  }
  return null;
};

export function selectEquipment(
  value,
  assetEquipments,
  setFiltered_Asset,
  setMeasureMinus,
  setStateMagnitude,
  setSelectedEquipment,
  setIndexEquipmentChannel,
  setSelectedChannels,
  setFilter,
  autocompleteRef,
) {
  let cont = 0;
  let selchannels = [];
  for (let index = 0; index < assetEquipments.length; index++) {
    for (let indexEquipment = 0; indexEquipment < assetEquipments[index].length; indexEquipment++) {
      selchannels.push([]);
      if (assetEquipments[index][indexEquipment].equipmentID === value) {
        setFiltered_Asset(assetEquipments[index][indexEquipment].asset);
        const measuresForStatus = [];
        if (assetEquipments[index][indexEquipment].pMeasureAlerts.includes(1)) {
          const count = assetEquipments[index][indexEquipment].pMeasureAlerts.filter((alert) => alert === 1).length;
          const notStatus =
            assetEquipments[index][indexEquipment].pMeasureNames.includes("depth_of_burial") && count === 1;
          if (!notStatus && count > 1) {
            setMeasureMinus(1);
            for (
              let indexStatus = 0;
              indexStatus < assetEquipments[index][indexEquipment].pMeasureAlerts.length;
              indexStatus++
            ) {
              if (
                assetEquipments[index][indexEquipment].pMeasureAlerts[indexStatus] === 1 &&
                assetEquipments[index][indexEquipment].pMeasureNames[indexStatus] !== "depth_of_burial"
              ) {
                measuresForStatus.push(assetEquipments[index][indexEquipment].pMeasureNames[indexStatus]);
              }
            }
            setStateMagnitude(measuresForStatus);
          } else {
            setMeasureMinus(0);
          }
        } else {
          setMeasureMinus(0);
          setStateMagnitude([]);
        }
        setSelectedEquipment(value);
        setIndexEquipmentChannel(cont);
      }
      cont++;
    }
  }
  //setSelectedChannels(selchannels);
  const ele = autocompleteRef.current.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
  if (ele) ele.click();
}

export function selectEquipmentInAssetReceiving(
  value,
  assetEquipments,
  setFiltered_Asset,
  setMeasureMinus,
  setStateMagnitude,
  setSelectedEquipment,
  setIndexEquipmentChannel,
  setSelectedChannels,
  autocompleteRef,
) {
  let cont = 0;
  let newEquipment = null;
  let newEquipmentIndex = null;
  for (let index = 0; index < assetEquipments.length; index++) {
    for (let indexEquipment = 0; indexEquipment < assetEquipments[index].length; indexEquipment++) {
      if (assetEquipments[index][indexEquipment].equipmentID === value) {
        setFiltered_Asset(assetEquipments[index][indexEquipment].asset);
        const measuresForStatus = [];
        if (assetEquipments[index][indexEquipment].pMeasureAlerts.includes(1)) {
          const count = assetEquipments[index][indexEquipment].pMeasureAlerts.filter((alert) => alert === 1).length;
          const notStatus =
            assetEquipments[index][indexEquipment].pMeasureNames.includes("depth_of_burial") && count === 1;
          if (!notStatus) {
            setMeasureMinus(1);
            for (
              let indexStatus = 0;
              indexStatus < assetEquipments[index][indexEquipment].pMeasureAlerts.length;
              indexStatus++
            ) {
              if (
                assetEquipments[index][indexEquipment].pMeasureAlerts[indexStatus] === 1 &&
                assetEquipments[index][indexEquipment].pMeasureNames[indexStatus] !== "depth_of_burial"
              ) {
                measuresForStatus.push(assetEquipments[index][indexEquipment].pMeasureNames[indexStatus]);
              }
            }
            setStateMagnitude(measuresForStatus);
          } else {
            setMeasureMinus(0);
          }
        } else {
          setMeasureMinus(0);
          setStateMagnitude([]);
        }
        newEquipment = value;
        newEquipmentIndex = cont;
      }
      cont++;
    }
  }
  if (isNil(newEquipment)) {
    newEquipment = assetEquipments[0][0].equipmentID;
  }
  if (isNil(newEquipmentIndex)) {
    newEquipmentIndex = 0;
  }
  setSelectedEquipment(newEquipment);
  setIndexEquipmentChannel(newEquipmentIndex);
}
