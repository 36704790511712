import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import { ThemeProvider } from "@mui/material/styles";
import { Autocomplete, Button, Checkbox, MenuItem, TextField } from "@mui/material";

import palette from "../../../config/colorPalette";
import { getMuiTheme, menuAccessVerification } from "../../utils/utils";
import { BOOLEAN_MEASURES } from "../../utils/consts";
import { setSelectedMenuOptionAction } from "../../redux/menuSlice";
import NormalDataVisualization from "./components/NormalDataVisualization";
import AddTraceModal from "./components/AddTraceModal";
import {
  handleModalAccept,
  //loadBooleanData,
  loadData,
  //loadDateOptions,
  initialSpatialSelectHandler,
  loadDateOptionsChannel,
  //loadDateOptions,
} from "./utils";
import BooleanDataVisualization from "./components/BooleanDataVisualization";
import { scaleLinear, interpolateRgb } from "d3";
// eslint-disable-next-line no-unused-vars
import { getAssetsValuesByAssets, getPointsByAssets } from "../../api";
import { setAssetsPointsData } from "../../redux/assetsPointsSlice";
import { setAssetsData } from "../../redux/assetsValuesSlice";
//import ExportToWord from "./components/ExportToWord";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";

const Analysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const userData = useSelector((state) => state.userData.data);
  const menuData = useSelector((state) => state.menuData.data);
  const assetsData = useSelector((state) => state.assetsData.data);
  const assetsPoints = useSelector((state) => state.assetsPoints);
  const configData = useSelector((state) => state.configData.data);
  const { assetName, dateTime, startPointIndex, endPointIndex, startDate, alertMeasure } = useParams();
  let auxAlertMeasure = alertMeasure;
  const [afterAlert, setAfterAlert] = useState(false);
  const [temporalData, setTemporalData] = useState();
  const [minsW, setMinsW] = useState([]);
  const [maxsW, setMaxsW] = useState([]);
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();

  const [loading, setLoading] = useState(true);
  const [loadingTemporal, setLoadingTemporal] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [asset, setAsset] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const [assetEquipments, setAssetEquipments] = useState();
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [equipment, setEquipment] = useState(null);
  const [equipmentChannels, setEquipmentChannels] = useState();
  const [asignedChannels, setAsignedChannels] = useState();
  const [asignedChannelNames, setAsignedChannelNames] = useState();
  const [channel, setChannel] = useState();
  const [dateTimeList, setDateTimeList] = useState([]);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dateOptions, setDateOptions] = useState([]);
  const [dateOptionsModal, setDateOptionsModal] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeOptionsModal, setTimeOptionsModal] = useState([]);
  const [measuresFiltered, setMeasuresFiltered] = useState([]);
  const [percentiles, setPercentiles] = useState();
  const [differences, setDifferences] = useState();

  const [data, setData] = useState();
  const dataReceivedRef = useRef(false);
  const pointsReceivedRef = useRef(false);
  // eslint-disable-next-line no-unused-vars
  const [booleanData, setBooleanData] = useState();
  const [currentAsset, setCurrentAsset] = useState();
  const [mapColors, setMapColors] = useState();

  const refs = useRef([]);

  const theme = useMemo(() => getMuiTheme(), []);

  const enabledDates = useMemo(() => {
    if (!isNil(dateOptions) && !isEmpty(dateOptions)) {
      return dateOptions;
    }
  }, [dateOptions]);

  const shouldDisableDate = (date) => {
    // Convertir la fecha a una cadena de fecha sin hora para compararla con las fechas habilitadas
    const dateString = format(date, "yyyy-MM-dd");
    return !enabledDates.some((enabledDate) => enabledDate === dateString);
  };

  useEffect(() => {
    if (menuAccessVerification("/analysis", menuData, navigate)) {
      const i = menuData.find((m) => m.page === "/analysis").value;
      dispatch(setSelectedMenuOptionAction(i));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  useEffect(() => {
    if (!isNil(configData)) {
      setMapColors(configData.colorMaps);
    }
  }, [configData]);

  const scales = useMemo(() => {
    if (!isNil(mapColors) && !isEmpty(mapColors)) {
      const jetDomain = [];
      const jetRange = [];
      const seismicDomain = [];
      const seismicRange = [];
      for (let index = 0; index < mapColors["jet"]["blue"].length; index++) {
        jetDomain.push(mapColors["jet"]["value"][index]);
        const redJET = Math.round(mapColors["jet"]["red"][index] * 255);
        const greenJET = Math.round(mapColors["jet"]["green"][index] * 255);
        const blueJET = Math.round(mapColors["jet"]["blue"][index] * 255);
        const hexJET = rgbToHex(redJET, greenJET, blueJET);
        jetRange.push(interpolateRgb(hexJET)(mapColors["jet"]["value"][index]));
        seismicDomain.push(mapColors["seismic"]["value"][index]);
        const redSEISMIC = Math.round(mapColors["seismic"]["red"][index] * 255);
        const greenSEISMIC = Math.round(mapColors["seismic"]["green"][index] * 255);
        const blueSEISMIC = Math.round(mapColors["seismic"]["blue"][index] * 255);
        const hexSeismic = rgbToHex(redSEISMIC, greenSEISMIC, blueSEISMIC);
        seismicRange.push(interpolateRgb(hexSeismic)(mapColors["seismic"]["value"][index]));
      }
      let result = {
        status: scaleLinear()
          .domain([0, 0.5, 0.7, 0.9])
          .range([
            interpolateRgb("green")(0),
            interpolateRgb("yellow")(0.5),
            interpolateRgb("orange")(0.7),
            interpolateRgb("red")(0.9),
          ]),
        leak: scaleLinear()
          .domain([0, 0.55])
          .range([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]),
        temperature: scaleLinear().domain(jetDomain).range(jetRange),
        landslide: scaleLinear()
          .domain([0, 0.55])
          .range([interpolateRgb("green")(0), interpolateRgb("red")(0.55)]),
        strain: scaleLinear().domain(seismicDomain).range(seismicRange),
      };
      return result;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapColors]);

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  useEffect(() => {
    if (!isNil(assetsData) && !isNil(userData) && isNil(asset) && isNil(currentAsset)) {
      const assetInit = !isNil(assetName) ? assetName : assetsData[0].name;
      setAsset(assetInit);
      const assetInfo = assetsData?.find((a) => a.name === assetInit);
      const equipment = assetInfo.equipments.find((item) => item.equipmentID === assetInfo.equipments[0].equipmentID);
      setSelectedEquipment(equipment);
      setEquipment(assetInfo.equipments[0].equipmentID);
      setAssetEquipments(assetInfo.equipments);
      const menuItems = [];
      for (let index = 0; index < assetInfo.equipments[0].channels.length; index++) {
        menuItems.push(index + 1);
      }
      setAsignedChannels(equipment.channels);
      setAsignedChannelNames(equipment.channelsNames);
      const print = equipment.channelsStates.indexOf(false);
      setChannel(print + 1);
      setEquipmentChannels(menuItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsData, userData]);
  useEffect(() => {
    async function setInfo() {
      if (!isNil(asset) && !isNil(assetsData)) {
        const assetInfo = assetsData?.find((a) => a.name === asset);
        //loadDateOptions({
        //  setDateOptions,
        //  setTimeOptions,
        //  asset,
        //  setDate,
        //  setTime,
        //});
        if (asset !== assetEquipments[0].asset) {
          setEquipment(assetInfo.equipments[0].equipmentID);
          const equipment = assetInfo.equipments.find(
            (item) => item.equipmentID === assetInfo.equipments[0].equipmentID,
          );
          setSelectedEquipment(equipment);
          setAssetEquipments(assetInfo.equipments);
          const menuItems = [];
          for (let index = 0; index < assetInfo.equipments[0].channels.length; index++) {
            menuItems.push(index + 1);
          }
          setAsignedChannels(equipment.channels);
          setAsignedChannelNames(equipment.channelsNames);
          setChannel(null);
          setEquipmentChannels(menuItems);
          setDateTimeList([]);
        }

        //setPoints(response.data);
        //const response2 = await getAssetsValuesByAssets(asset);
        //setAssetsValues(response2.data);
        //dispatch(setAssetsData(response2.data));
      }
    }
    setInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assetsData]);

  useEffect(() => {
    async function getTimes() {
      if (!isNil(asset)) {
        if (channel === null) {
          const print = selectedEquipment.channelsStates.indexOf(false);
          setChannel(print + 1);
        }
        loadDateOptionsChannel({
          setDateOptions,
          setDateOptionsModal,
          setTimeOptions,
          setTimeOptionsModal,
          assetEquipments,
          equipmentChannels,
          asset,
          equipment,
          channel,
          dateTime,
          setDate,
          setTime,
          setChannel,
          firstTime,
          setFirstTime,
          selectedEquipment,
          setSelectedEquipment,
        });
      }
    }
    getTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (!isNil(date) && !isNil(time) && !isNil(dateOptions) && !isNil(timeOptions)) {
      const newDateTime = `${dateOptions[date]}T${timeOptions[time]}`;
      if (!isNil(dateTimeList)) {
        setDateTimeList([newDateTime]);
      }
    } else {
      setData({});
      dispatch(setAssetsPointsData({}));
      dispatch(setAssetsData({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time, dateOptions, timeOptions]);
  useEffect(() => {
    async function getDataAndPoints() {
      const alreadyPoints = assetsPoints.data;
      if (!isNil(alreadyPoints)) {
        const puntosExistentes = (alreadyPoints, asset) => {
          return Object.keys(alreadyPoints).some((key) => key.startsWith(asset));
        };
        if (!puntosExistentes(alreadyPoints, asset) && !isNil(asset)) {
          const response = await getPointsByAssets(asset);
          const pointsMap = {};
          for (let index = 0; index < response.data.data.length; index++) {
            pointsMap[`${asset}${index + 1}`] = response.data.data[index];
          }
          dispatch(setAssetsPointsData(pointsMap));
        }
      }
      if (
        !isNil(asset) &&
        !isNil(dateTimeList) &&
        !isEmpty(dateTimeList) &&
        !isNil(assetsData) &&
        !isNil(selectedEquipment) &&
        !isNil(assetsPoints.data) &&
        !isEmpty(assetsPoints.data) &&
        !dataReceivedRef.current &&
        !isNil(assetsPoints.data[`${asset}${asignedChannels[channel - 1] + 1}`])
      ) {
        dataReceivedRef.current = true;
        const assetInfo = assetsData?.find((a) => a.name === asset);
        loadData({
          asset: assetInfo,
          selectedEquipment,
          points: assetsPoints.data[`${asset}${asignedChannels[channel - 1] + 1}`],
          dateTimeList,
          data,
          equipment_ID: equipment,
          channel: channel,
          setData,
          setBooleanData,
          setLoading,
          setPercentiles,
          setDifferences,
        });
        if (isNil(currentAsset) || currentAsset.name !== asset) {
          setCurrentAsset(assetsData.find((a) => a.name === asset));
        }
      }
    }
    getDataAndPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTimeList, assetsPoints]);

  useEffect(() => {
    let start = null;
    let end = null;
    if (
      !isNil(data) &&
      !isEmpty(data) &&
      !isNil(selectedEquipment?.pMeasureNames) &&
      !isNil(dateTimeList) &&
      !isEmpty(dateTimeList) &&
      dateTimeList.length === 1
    ) {
      const m = selectedEquipment.pMeasureNames.find(
        (x) => !isNil(data[0]?.[x]?.values) && !isEmpty(data[0]?.[x].values),
      );

      if (isNil(startPointIndex) && isNil(endPointIndex)) {
        setLoadingTemporal(false);
      }
      if (!isNil(startPointIndex) || (!isNil(startPointIndex) && isNil(endPointIndex))) {
        start = parseInt(startPointIndex);
        setStartIndex(start);
      }
      if (!isNil(endPointIndex)) {
        end = parseInt(endPointIndex);
        setEndIndex(end);
      }
      if (!isNil(start) && !isNil(end)) {
        const spatialDataList = data.map((d) => (!isNil(d[m]) ? { ...d[m], pk: d.pk } : {}));
        const series = spatialDataList?.map((d) => ({
          name: d.datetime,
          data: d.values,
        }));
        initialSpatialSelectHandler({
          data: { series },
          start: start,
          end: end,
          measureName: m,
          setTemporalDataList: setTemporalData,
          minsWaterfall: minsW,
          setMinsWaterfall: setMinsW,
          maxsWaterfall: maxsW,
          setMaxsWaterfall: setMaxsW,
          currentAsset,
          spatialDataList,
          setLoading: setLoadingTemporal,
          dateTime,
          selectedEquipment,
          equipment,
          channel,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPointIndex, endPointIndex, data, currentAsset, dateTimeList]);

  if (isNil(assetsData) || isNil(data) || loading) {
    const message =
      isNil(dateOptions) &&
      isNil(timeOptions) &&
      isNil(dateOptionsModal) &&
      isNil(timeOptionsModal) &&
      isNil(date) &&
      isNil(time)
        ? "data.didnt.came"
        : "loading";
    return (
      <div style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {`${intl.formatMessage({ id: message })}`}
        {message === "data.didnt.came" && !isNil(assetsData) && Array.isArray(data) && data.length === 0 ? (
          <>
            {` ${intl.formatMessage({ id: "select.another.asset" })}`}
            <TextField
              variant="outlined"
              margin="dense"
              id="asset"
              label={intl.formatMessage({ id: "Asset" })}
              select
              style={{ width: 150, margin: "1%" }}
              value={asset}
              onChange={(event) => {
                dataReceivedRef.current = false;
                pointsReceivedRef.current = false;
                setFirstTime(true);
                setAsset(event.target.value);
              }}
            >
              {assetsData.map((opt) => (
                <MenuItem key={opt.name} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </TextField>
          </>
        ) : null}
        {message === "loading" && "..."}
      </div>
    );
  }

  if (!isNil(assetsData) && !loading) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "0.5rem",
                padding: "1rem",
                width: "99%",
              }}
            >
              <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="asset"
                  label={intl.formatMessage({ id: "Asset" })}
                  select
                  style={{ width: 150 }}
                  value={asset}
                  onChange={(event) => {
                    pointsReceivedRef.current = false;
                    dataReceivedRef.current = false;
                    setLoading(true);
                    setFirstTime(true);
                    setAsset(event.target.value);
                  }}
                >
                  {assetsData.map((opt) => (
                    <MenuItem key={opt.name} value={opt.name}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </TextField>
                {!isNil(assetEquipments) && assetEquipments.length > 1 && (
                  <TextField
                    select
                    style={{ width: 150 }}
                    id="asset_equipments"
                    variant="outlined"
                    label={intl.formatMessage({ id: "equipments" })}
                    margin="dense"
                    value={equipment}
                    onChange={(event) => {
                      dataReceivedRef.current = false;
                      setLoading(true);
                      const assetInfo = assetsData?.find((a) => a.name === asset);
                      const equipment = assetInfo.equipments.find((item) => item.equipmentID === event.target.value);
                      setSelectedEquipment(equipment);
                      setAsignedChannels(equipment.channels);
                      setAsignedChannelNames(equipment.channelsNames);
                      setChannel(null);
                      setTimeout(() => {
                        setEquipment(event.target.value);
                        const print = equipment.channelsStates.indexOf(false);
                        setChannel(print + 1);
                      }, 0);
                    }}
                  >
                    {assetEquipments.map((ae, i) => (
                      <MenuItem key={`${ae.equipmentID}_current_select_${i}`} value={ae.equipmentID}>
                        {ae.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {!isNil(equipmentChannels) && equipmentChannels.length > 1 && (
                  <TextField
                    select
                    style={{ width: 150 }}
                    id="equipments_channels"
                    variant="outlined"
                    label={intl.formatMessage({ id: "channel" })}
                    margin="dense"
                    value={channel}
                    onChange={(event) => {
                      dataReceivedRef.current = false;
                      setChannel(event.target.value);
                    }}
                  >
                    {equipmentChannels.map((ec, i) => {
                      const print = selectedEquipment.channelsStates[i];
                      if (print) {
                        return null;
                      }
                      return (
                        <MenuItem key={`${ec}_current_select_${i}`} value={ec}>
                          {asignedChannelNames[ec - 1]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
                {!isEmpty(dateOptions) && !isNil(dateOptions) && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      id="date_picker"
                      label={intl.formatMessage({ id: "date" })}
                      shouldDisableDate={shouldDisableDate}
                      format="dd-MM-yyyy"
                      value={parseISO(dateOptions[date])}
                      onChange={(e) => {
                        dataReceivedRef.current = false;
                        setLoading(true);
                        const date = new Date(e);

                        const year = date.getFullYear();
                        const month = ("0" + (date.getMonth() + 1)).slice(-2);
                        const day = ("0" + date.getDate()).slice(-2);

                        const formattedDate = year + "-" + month + "-" + day;
                        loadDateOptionsChannel({
                          //setDateOptions,
                          setTimeOptions,
                          date: dateOptions[dateOptions.indexOf(formattedDate)],
                          dateOptions,
                          assetEquipments,
                          asset,
                          equipment,
                          channel,
                          equipmentChannels,
                          setDate,
                          setTime,
                          firstTime,
                          setFirstTime,
                          selectedEquipment,
                          setSelectedEquipment,
                        });
                      }}
                    />
                  </LocalizationProvider>
                )}
                {!isEmpty(timeOptions) && !isNil(timeOptions) && (
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="trace_date"
                    label={intl.formatMessage({ id: "time" })}
                    select
                    style={{ width: 150 }}
                    value={time}
                    onChange={(event) => {
                      dataReceivedRef.current = false;
                      setLoading(true);
                      setTime(event.target.value);
                    }}
                  >
                    {timeOptions?.map((opt, i) => (
                      <MenuItem key={`times-${i}`} value={i}>
                        {opt}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
              {!isNil(selectedEquipment) && (
                <Autocomplete
                  multiple
                  id="multiple-checkboxes"
                  options={selectedEquipment.pMeasureNames}
                  disableCloseOnSelect
                  onChange={(event, values) => {
                    setMeasuresFiltered(values);
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {intl.formatMessage({ id: option })}
                    </li>
                  )}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={intl.formatMessage({ id: "select.graphs" })} />
                  )}
                />
              )}

              {/*!isNil(refs.current) && !isEmpty(refs.current) && !isNil(currentAsset) && (
              <ExportToWord
                asset={asset}
                date={dateOptions[date]}
                time={timeOptions[time]}
                charts={currentAsset.pMeasureNames.map((m, i) => ({ title: m, ref: refs.current[i] }))}
              />
            )*/}
              <Button
                variant="contained"
                style={{ height: "2.5rem", backgroundColor: palette.primary, color: "white" }}
                size="small"
                onClick={() => setOpenModal(true)}
              >
                {intl.formatMessage({ id: "add reading" })}
              </Button>
            </div>
            <div
              style={{
                display: "grid",
                gap: "1rem",
                overflowY: "scroll",
                overflowX: "hidden",
                width: "98%",
                height: "75vh",
              }}
            >
              {!isNil(dateTimeList) && !isEmpty(dateTimeList) && !isNil(selectedEquipment) ? (
                // eslint-disable-next-line array-callback-return
                selectedEquipment.pMeasureNames.map((m, i) => {
                  if (measuresFiltered.includes(m) || measuresFiltered.length === 0) {
                    return BOOLEAN_MEASURES.includes(m) ? (
                      <div
                        style={{
                          position: "relative",
                          width: "99%",
                        }}
                        ref={(ref) => (refs.current[i] = ref)}
                      >
                        {!isNil(booleanData) && !isEmpty(booleanData) ? (
                          <BooleanDataVisualization
                            key={`${asset}-boolean-${m}-${i}`}
                            data={booleanData.map((d) => (!isNil(d[m]) ? { ...d[m], pk: d.pk } : {}))}
                            measureName={m}
                            assetName={asset}
                            asignedChannels={asignedChannels}
                            currentAsset={currentAsset}
                            title={m}
                            assetsPoints={assetsPoints}
                            initialPointIndex={startIndex ?? 0}
                            endPointIndex={endIndex ?? booleanData?.[0]?.[m]?.values?.length - 1}
                            loadingTemporal={loadingTemporal}
                            scales={scales}
                            percentiles={percentiles}
                            differences={differences}
                            equipment={equipment}
                            channel={channel}
                            selectedEquipment={selectedEquipment}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "20rem",
                              backgroundColor: "rgb(176 176 176 / 60%)",
                              zIndex: 1000,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              margin: "1rem",
                              padding: "0.5rem",
                              gap: "1rem",
                            }}
                          >
                            {`${intl.formatMessage({ id: "loading" })}...`}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          width: "99%",
                        }}
                        ref={(ref) => (refs.current[i] = ref)}
                      >
                        {!isNil(data) && !isEmpty(data) && !isNil(m) ? (
                          <NormalDataVisualization
                            key={`${asset}-normal-${m}-${i}`}
                            data={data.map((d) => (!isNil(d[m]) ? { ...d[m], pk: d.pk } : {}))}
                            currentAsset={currentAsset}
                            assetName={asset}
                            asignedChannels={asignedChannels}
                            title={m}
                            measureName={m}
                            currentDateTimeList={dateTimeList}
                            //boundaries={currentAsset.pBoundaries[i]}
                            initialPointIndex={startIndex ?? 0}
                            endPointIndex={endIndex ?? data?.[0]?.[m]?.values?.length - 1}
                            assetsPoints={assetsPoints}
                            temporalData={temporalData?.[m]}
                            maxsW={maxsW}
                            minsW={minsW}
                            isAlert={!isNil(dateTime)}
                            loadingTemporal={loadingTemporal}
                            datetime={dateTimeList.length > 1 ? dateTimeList[date] : dateTimeList[0]}
                            scales={scales}
                            percentiles={percentiles}
                            differences={differences}
                            equipment={equipment}
                            channel={channel}
                            selectedEquipment={selectedEquipment}
                            colorMaps={configData.colorMaps}
                            alertMeasure={auxAlertMeasure}
                            startDate={startDate}
                            afterAlert={afterAlert}
                            setAfterAlert={setAfterAlert}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "20rem",
                              backgroundColor: "rgb(176 176 176 / 60%)",
                              zIndex: 1000,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              margin: "1rem",
                              padding: "0.5rem",
                              gap: "1rem",
                            }}
                          >
                            {`${intl.formatMessage({ id: isEmpty(data) ? "no.data" : "loading" })}...`}
                          </div>
                        )}
                      </div>
                    );
                  }
                })
              ) : (
                <div
                  style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}
                >{`${intl.formatMessage({ id: "loading" })}...`}</div>
              )}
            </div>
          </div>
        </ThemeProvider>
        <AddTraceModal
          asset={asset}
          equipment={equipment}
          channel={channel}
          open={openModal}
          setOpen={setOpenModal}
          timeOptionsModal={timeOptionsModal}
          dateOptionsModal={dateOptionsModal}
          setTimeOptionsModal={setTimeOptionsModal}
          setDateOptionsModal={setDateOptionsModal}
          dataReceivedRef={dataReceivedRef}
          handleModalAccept={(dateIndex, timeIndex) => {
            dataReceivedRef.current = false;
            handleModalAccept({
              date: dateOptionsModal[dateIndex],
              time: timeOptionsModal[timeIndex],
              dateTimeList,
              setDateTimeList,
            });
          }}
        />
      </>
    );
  }
};

export default Analysis;
